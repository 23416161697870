import React, {useEffect, useState} from "react"
import {useSort, HeaderCellSort} from '@table-library/react-table-library/sort';
import {useTheme} from '@table-library/react-table-library/theme';
import {getTheme} from '@table-library/react-table-library/baseline';
import {Table, Header, HeaderRow, Body, Row, Cell, HeaderCell} from '@table-library/react-table-library/table';
import {Amplify, Auth, Hub, API} from 'aws-amplify'
import {AWSConfig} from './aws-exports'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import './App.css';

const Projects = () => {

    const theme = useTheme(getTheme())
    const [projects, setProjects] = useState([])
    const [user, setUser] = useState([])
    const [showProjects, setShowProjects] = useState(false)
    const [search, setSearch] = useState('');
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showNewProjectDialog, setShowNewProjectDialog] = useState(false);
    const [recordToEdit, setRecordToEdit] = useState({})
    const data = {
        nodes: projects.filter((item) => item.project.toLowerCase().includes(search.toLowerCase())),
    };

    const sort = useSort(data, {
        state: {
            sortKey: 'PROJECT', reverse: false,
        }, onChange: onSortChange,
    }, {
        sortFns: {
            PROJECT: (array) => array.sort((a, b) => a.project.localeCompare(b.project)),
            // SPEEDTYPE: (array) => array.sort((a, b) => a.speedtype.toString().localeCompare(b.project.toString())),
            TECHNICAL_CONTACT: (array) => array.sort((a, b) => a.technical_contact.localeCompare(b.technical_contact)),
            ACTIVE: (array) => array.sort((a, b) => a.active - b.active),
        },
    });


    function onSortChange(action, state) {
        console.log(action, state);
    }

    const fetchData = async () => {
        API.get('projects', 'projects', {})
            .then(response => {
                const data = response.projects;
                data.forEach((item, i) => {
                    item.id = i + 1;
                });
                setProjects(data);
            })
            .catch(error => {
                console.log(error.response);
            });
    }

    const handleRowClick = (item) => {
        setRecordToEdit(item);
        setShowEditDialog(true);
    };

    const handleNewProjectClick = () => {
        setRecordToEdit({'active': true});
        setShowNewProjectDialog(true);
    };

    const handleEditDialogSave = () => {
        setShowEditDialog(false);
        API.post('projects', 'projects', {body: recordToEdit})
            .then(response => {
                fetchData();
            })
            .catch(error => {
                console.log(error.response);
            });
    };

    const handleEditDialogCancel = () => {
        setShowEditDialog(false);
        setRecordToEdit({});
    };

    const handleNewProjectDialogSave = () => {
        console.log("Creating new project: " + JSON.stringify(recordToEdit));
        setShowNewProjectDialog(false);
        setSearch(recordToEdit.project)
        API.post('projects', 'projects', {body: recordToEdit})
            .then(response => {
                fetchData();
            })
            .catch(error => {
                console.log(error.toString());
            });
    };

    const handleNewProjectDialogCancel = () => {
        setShowNewProjectDialog(false);
        setRecordToEdit({});
    };

    const handleSpeedtypeUpdate = (event) => {
        setRecordToEdit(prevState => ({
            ...prevState,
            speedtype: event.target.value
        }));
    }

    const handleNewProjectUpdate = (event) => {
        console.log(JSON.stringify(recordToEdit));
        setRecordToEdit(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value,
        }));
    }

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        Amplify.configure(AWSConfig)
        Hub.listen('auth', ({payload: {event, data}}) => {
            switch (event) {
                case 'signIn':
                    // console.log('sign in', event, data)
                    setUser({user: data})
                    break
                case 'signOut':
                    console.log('sign out')
                    setUser({user: null})
                    break
            }
        })

        Auth.currentAuthenticatedUser().then(data => {
            // console.log('currentAuthenticatedUser', data)
            setUser({user: data})
            fetchData().then(() => {
                setShowProjects(true);
            })
        }).catch(() => {
            console.log('Not signed in')
            Auth.federatedSignIn()
        })
    }, [])

    return (<>
        {showProjects && <div>
            <table className="top-nav">
                <tr><td>
                <Button className="top-nav-buttons" variant="contained" onClick={() => handleNewProjectClick()}>
                    New Project
                </Button>
                    </td>
                    <td>
                <label className="top-nav-button" htmlFor="search">
                    <b>Search Projects:</b> <input id="search" type="text" onChange={handleSearch}/>
                </label>
                        </td>
                    </tr>
            </table>
            <Table data={data} sort={sort} theme={theme} layout={{fixedHeader: true}}>{(tableList) => (<>
                <Header>
                    <HeaderRow>
                        <HeaderCellSort sortKey="PROJECT">Project</HeaderCellSort>
                        {/*<HeaderCellSort sortKey="SPEEDTYPE">SpeedType</HeaderCellSort>*/}
                        <HeaderCell>SpeedType</HeaderCell>
                        <HeaderCellSort sortKey="TECHNICAL_CONTACT">Technical Support Contact</HeaderCellSort>
                        <HeaderCellSort sortKey="ACTIVE">Active</HeaderCellSort>
                    </HeaderRow>
                </Header>
                <Body>
                    {tableList.map((item) => (
                        <Row key={item.id} item={item} onClick={(event) => handleRowClick(item)}>
                            <Cell>{item.project}</Cell>
                            <Cell>{item.speedtype}</Cell>
                            <Cell>{item.technical_contact}</Cell>
                            <Cell>{item.active ? "true" : "false"}</Cell>
                        </Row>
                    ))}
                </Body>
            </>)}
            </Table>
        </div>}
        <Dialog open={showEditDialog} onClose={handleEditDialogCancel}>
            <DialogTitle>Edit Project: {recordToEdit.project}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <TextField onChange={handleSpeedtypeUpdate}
                           autoFocus
                           margin="dense"
                           id="speedtype"
                           label="Speedtype"
                           type="text"
                           fullWidth
                           variant="standard"
                           defaultValue={recordToEdit.speedtype}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleEditDialogSave}>Save</Button>
                <Button onClick={handleEditDialogCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showNewProjectDialog} onClose={handleNewProjectDialogCancel}>
            <DialogTitle>New Project: {recordToEdit.project}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <TextField onBlur={handleNewProjectUpdate}
                           autoFocus
                           margin="dense"
                           id="project"
                           label="Project"
                           type="text"
                           fullWidth
                           variant="standard"
                           defaultValue={recordToEdit.project}
                />
                <TextField onBlur={handleNewProjectUpdate}
                           autoFocus
                           margin="dense"
                           id="speedtype"
                           label="Speedtype"
                           type="text"
                           fullWidth
                           variant="standard"
                           defaultValue={recordToEdit.speedtype}
                />
                <TextField onBlur={handleNewProjectUpdate}
                           autoFocus
                           margin="dense"
                           id="technical_contact"
                           label="Technical Contact"
                           type="text"
                           fullWidth
                           variant="standard"
                           defaultValue={recordToEdit.techical_contact}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNewProjectDialogSave}>Save</Button>
                <Button onClick={handleNewProjectDialogCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default Projects
