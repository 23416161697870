import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals';
import UMassChanLogo from './umasschan.png'
// import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
            <table className="header">
                <tr>
                    <td>
                        <img src={UMassChanLogo} width="100" alt="UMass Chan logo"></img>
                    </td>
                    <td>
                        <h1>Cloud Billing</h1></td>
                </tr>
            </table>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
