import {Auth} from "aws-amplify";

export const AWSConfig = {
    Auth: {
        // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-1:b7a6c8c4-3260-4ee8-8596-201745a15f14',

        // (required)- Amazon Cognito Region
        region: 'us-east-1',

        // (optional) - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'XX-XXXX-X',

        // (optional) - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_qEaD86Grv',

        // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
        userPoolWebClientId: '6mh3m531c9526mioh3eeolo8r6',

        // (optional) - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,

        // (optional) - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
            // - Cookie domain (only required if cookieStorage is provided)
            domain: 'dev.billing.ummsresearch.org',
            // (optional) - Cookie path
            path: '/',
            // (optional) - Cookie expiration in days
            expires: 365,
            // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            sameSite: 'strict',
            // | 'lax',
            // (optional) - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        },

        // (optional) - customized storage object
        // storage: MyStorage,

        // (optional) - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH',

        // (optional) - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

        // (optional) - Hosted UI configuration
        oauth: {
            domain: 'auth.dev.billing.ummsresearch.org',
            scope: [
                'phone',
                'email',
                'profile',
                'openid',
                // 'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: 'https://dev.billing.ummsresearch.org/',
            redirectSignOut: 'https://dev.billing.ummsresearch.org/',
            clientId: '6mh3m531c9526mioh3eeolo8r6',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    },
    API: {
        endpoints: [
            {
                name: "projects",
                endpoint: "https://od5d788dwf.execute-api.us-east-1.amazonaws.com/dev/",
                custom_header: async () => {
                    // return {Authorization: 'token'}
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
                }
            }
        ]
    }
}